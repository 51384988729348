export const currencies = [
  {
    address: "0x0000000000000000000000000000000000000Eee",
    symbol: "BONE",
    type: 1,
    decimals: 18,
  },
  {
    address: "0x0000000000000000000000000000000000000FfF",
    symbol: "Lock Token",
    type: 3,
    decimals: 18,
  },
];
